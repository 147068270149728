<script lang="ts">
    import { inertia } from '@inertiajs/svelte';
    import * as Card from '$lib/components/ui/card';
    import * as Tooltip from '$lib/components/ui/tooltip';
    import { Input } from '$lib/components/ui/input';
    import HammerIcon from '../../Icons/HammerIcon.svelte';
    import PostListIcon from '../../Icons/PostListIcon.svelte';
    import { Button } from '$lib/components/ui/button';
    import { Icon, Bell, Users } from 'svelte-hero-icons';
    import Modal from '../../Components/Modal.svelte';
    import { createEventDispatcher, getContext } from 'svelte';
    import axios from 'axios';
    import { writable } from 'svelte/store';
    import { onMount } from 'svelte';

    export let user: any;

    const dispatch = createEventDispatcher();
    let nextBidMinimumPrice = user.auction.latest_bid ? minimumPriceCalculator(user.auction.latest_bid.amount) : 0.1;
    let latestPrice = user.auction && user.auction.latest_bid ? user.auction.latest_bid.amount : '0';
    const showModal = writable(false);

    const referralClaimContext = getContext('referralClaimContext');
    const { authUser } = referralClaimContext;

    let modalData = {
        current_price: 0,
        user_id: user.id,
        bidder_user_id: authUser,
    };

    onMount(async () => {
        if (user.auction && user.auction.latest_bid) {
            initTheTimer(user.auction.latest_bid.bid_validity, user.id);
        }
    });

    function updateBidPrice(event) {
        modalData.current_price = event.target.value;
    }

    function handleClick() {
        dispatch('childClick', user.auction.id);
    }

    function getCurrentTimestamp() {
        return new Date().toISOString().slice(0, 19).replace('T', ' ');
    }

    function initTheTimer(bidValidity, id) {
        const currentTime = getCurrentTimestamp();
        initTimer(bidValidity, currentTime, id);
        return '';
    }

    function handlePlaceBid() {
        showModal.set(true);
        dispatch('postBidClick', user.auction.id);
    }

    function handlePlaceBidSubmit() {
        if (nextBidMinimumPrice > modalData.current_price) {
            launchToast('error', trans('Error'), 'the price should be more than ' + nextBidMinimumPrice);
            return false;
        }

        axios
            .post('/referral-claim/place-bid', modalData)
            .then((response) => {
                user.auction.bids_count = user.auction.bids_count + 1;
                latestPrice = parseFloat(response.data.current_bid_price);
                nextBidMinimumPrice = minimumPriceCalculator(latestPrice);
                modalData.current_price = nextBidMinimumPrice;
                initTimer(response.data.validity, response.data.nowTime, response.data.user_id);

                launchToast('success', trans('Success'), response.data.message);
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    const errors = error.response.data.error;
                    launchToast('danger', trans('Error'), trans(errors));
                } else {
                    launchToast('danger', trans('Error'), trans(error.message));
                }
            });
    }

    function minimumPriceCalculator(inputPrice) {
        if (inputPrice < 5) {
            const number = 0.2 + inputPrice;
            return parseFloat(number.toFixed(2));
        }
        if (inputPrice > 5 && inputPrice < 10) {
            const number = 0.5 + inputPrice;
            return parseFloat(number.toFixed(2));
        }
        const number = inputPrice / 10;
        const formattedNumber = parseFloat(number.toFixed(2));
        const totalOutput = formattedNumber + inputPrice;
        return totalOutput.toFixed(2);
    }
</script>

<Card.Root class="w-75 mb-3 bg-gray-100">
    <Card.Content class="d-flex items-center p-3 pt-3">
        <div class="userImg w-25 pr-3">
            <img src="{user.avatar}" alt="avatar" class="rounded-circle user-avatar" />
        </div>
        <div class="userDetail w-75 d-flex items-start">
            <div class="userBidDetails w-90 mx-3">
                <a href="{window.location.host + '/' + user.username}" target="blank">
                    <h6 class="m-0 font-bold">{user.name} (22)</h6>
                </a>
                <p class="m-0">@twitter</p>

                <Input
                    disabled
                    type="text"
                    value="{latestPrice}"
                    class="text-bold mb-2 mt-1 text-center text-xl text-black"
                />

                <div class="d-flex mb-2 justify-between">
                    <Tooltip.Root>
                        <Tooltip.Trigger>
                            <span class="d-flex">
                                <svelte:component this="{PostListIcon}" /> &nbsp; {user.posts_count}
                            </span>
                        </Tooltip.Trigger>
                        <Tooltip.Content>Posts</Tooltip.Content>
                    </Tooltip.Root>
                    <Tooltip.Root>
                        <Tooltip.Trigger>
                            <span class="d-flex">
                                <Icon src="{Users}" size="20" /> &nbsp; {user.subscribers_count}
                            </span>
                        </Tooltip.Trigger>
                        <Tooltip.Content>Subscribers</Tooltip.Content>
                    </Tooltip.Root>
                </div>

                <Button
                    variant="outline"
                    class="w-100 btn-sm btn d-flex w-100 post-btn-gradient m-0 mr-2 items-center justify-center text-white"
                    size="sm"
                    on:click="{handlePlaceBid}">Place Bid</Button
                >
            </div>
            <div class="bidDetails w-50">
                <div class="mb-2 rounded-lg bg-gray-200 p-3">
                    <div class="d-flex mb-2 justify-between">
                        <svelte:component this="{HammerIcon}" />

                        <span class="w-75 rounded-md bg-white px-2 text-center font-semibold">
                            <span id="{'timer_' + user.id}"> 00:00:00 </span>
                        </span>
                    </div>
                    <div class="mb-2 rounded-md bg-white px-2 text-center">
                        {user.auction.unique_bidders_count} bidders
                    </div>
                    <div class="rounded-md bg-white px-2 text-center">
                        {user.auction.bids_count} bids
                    </div>
                </div>
                <div class="d-flex">
                    <a
                        use:inertia
                        href="{`/markets/my/auction/${user.auction.id}`}"
                        class="btn-sm btn d-flex w-50 post-btn-gradient m-0 mr-2 items-center justify-center text-white"
                        >Profile</a
                    >
                    <Button variant="outline" size="sm" on:click="{handleClick}"><Icon src="{Bell}" size="20" /></Button
                    >
                </div>
            </div>
        </div>
    </Card.Content>

    <Modal bind:showModal="{$showModal}">
        <h2 slot="header">Place Bid</h2>

        <p>Minimum Price for Bid {nextBidMinimumPrice}</p>

        <input
            type="text"
            class="border-blue-gray-200 text-blue-gray-700 placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 disabled:bg-blue-gray-50 peer h-full w-full rounded-[7px] border bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0"
            placeholder="Your bid price"
            on:input="{updateBidPrice}"
            bind:value="{modalData.current_price}"
            required
        />

        <Button
            variant="outline"
            class="w-100 btn-sm btn d-flex w-100 post-btn-gradient m-0 mr-2 items-center justify-center text-white"
            size="sm"
            on:click="{handlePlaceBidSubmit}">Place Bid</Button
        >
    </Modal>
</Card.Root>
